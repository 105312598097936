import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                open: 'open',
            }
        }
    }

    init() {
        
    }

    open(e) {
        const index = e.currentTarget.getAttribute('data-index');
        const items = this.$('item');
        items.forEach(element => { 
            element.classList.remove('is-active');
            if(element.getAttribute('data-index') == index) {
                element.classList.add('is-active');
            }
        });
        const opens = this.$('open');
        opens.forEach(element => element.classList.remove('is-active'));
        e.currentTarget.classList.add('is-active');
        // const items2 = document.querySelectorAll('[data-index="'+index+'"]');
        // items2.forEach(element => element.classList.add('is-active'));

        if (this.getData('timeline')) {
            // this.$('head')[0].style.transform = 'translateX(calc(-'+index+' * 100%))';
            opens.forEach(element => {
                if(element.classList.contains('is-active')) {
                    var width = $(element).parent('.c-tab_head').width();
                    element.style.transform = 'translateX(calc(-'+index+' * 20% - ('+index+' * 1rem)))';
                    element.style.width = width+'px';
                }else {
                    element.style.transform = 'translateX(calc(-'+index+' * 100%))';
                    // element.style.width = '178px';
                    element.style.width = 'auto';
                }
            });
        }

        this.call('update', false, 'Scroll'); // update Locomotive Scroll
        
    }
}
