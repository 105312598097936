import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                submitAjax: 'submitAjax',
                submit: 'submit',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      var $ = jQuery;
      var $msg_error = 'Ce champ est obligatoire';
      var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';

      if(this.getData('form')) {
        const form = this.getData('form');
        $(form).validate({
          ignore: [],
          lang: 'fr',
          errorElement : 'div',
          errorPlacement: function(error, element) {
            var placement = $(element).data('error');
            if (placement) {
              $(placement).append(error);
            } else {
              error.insertAfter(element);
            }
          },
        });
        const required = this.$('required');
        const rules = [];
        required.forEach(function(element) {
          $(element).rules('add', { required: true, messages : { required : $msg_error } });
          if ($(element).attr('type') == 'email') {
            $(element).rules('add', { email: true, messages : { email : $msg_error_email } });
          }
        });
      }

    }

    submitAjax(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        $.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: form.serialize(),
          beforeSend: function() {
            form.find('.c-form_action .c-button').addClass('-disabled');
            form.find('.c-form_msg.-save').addClass('u-margin-top-small');
            form.find('.c-form_msg').removeClass('error');
            form.find('.c-form_msg').removeClass('success');
          },
          success: function( response ){

            if(response.redirectto) {
              window.location.href = response.redirectto;
            }
            if(response.msg) {
              form.find('.c-form_msg').html(response.msg).fadeIn(1000);
            }
            if(response.status == 'success') {
              form.find('.c-form_msg').removeClass('error');
              form.find('.c-form_msg').addClass('success');
              if(response.reset) {
                form[0].reset();
              }
            }else {
              form.find('.c-form_msg').removeClass('success');
              form.find('.c-form_msg').addClass('error');
            }
          },
          complete: function() {
            form.find('.c-form_action .c-button').removeClass('-disabled');
          },
        });
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    submit(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        form.submit();
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }
}
